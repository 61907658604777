import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import Content from "../common/Content";
import MarkdownContent from "../common/MarkdownContent";
import PrettyList from "../common/PrettyList";
import StaticMap from "../common/StaticMap";
import Testimonials from "../common/Testimonials";
import styles from "./Area.module.scss";

class Area extends React.Component {
  render() {
    const { location, siteTitle, area, services, testimonials } = this.props;
    const shortTitle = area.frontmatter.title.replace(", CO", "");
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={area.frontmatter.title}
          description={`${shortTitle} pruning, tree removal, tree planting, and cabling & support systems specialists.`}
          keywords={[`trees`, `tree care`, `pruning`, `tree trimming`]}
        />
        <Hero title={area.frontmatter.title} />
        {Boolean(area.html) && (
          <Content>
            <MarkdownContent html={area.html} />
          </Content>
        )}
        {testimonials.length > 0 && (
          <div className={styles.testimonials}>
            <Content>
              <h3 className={styles.testimonialsTitle}>
                Here's what our customers had to say...
              </h3>
              <Testimonials area={area} testimonials={testimonials} />
            </Content>
          </div>
        )}
        {services.length > 0 && (
          <PrettyList
            className={styles.services}
            title={<h2 className="sr-only">Services</h2>}
            items={services.map((service) => ({
              title: service.frontmatter.title || service.fields.slug,
              description: service.frontmatter.description || service.excerpt,
              slug: service.fields.slug,
            }))}
          />
        )}
        <StaticMap
          latitude={area.frontmatter.latitude}
          longitude={area.frontmatter.longitude}
          zoom={8}
        />
      </Layout>
    );
  }
}

Area.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  area: PropTypes.object.isRequired,
  testimonials: PropTypes.array.isRequired,
};

export default Area;
