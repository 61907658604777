import React from "react";
import PropTypes from "prop-types";
import { MAPBOX_TOKEN } from "../../../constants";

// eslint-disable-next-line max-len
export default function StaticMap({
  title,
  latitude,
  longitude,
  zoom,
  bearing,
  pitch,
  height,
  width,
}) {
  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/${longitude},${latitude},${zoom},${bearing},${pitch}/${width}x${height}`;
  const query = `?access_token=${MAPBOX_TOKEN}`;
  return (
    <div className="otc-static-map">
      <img
        className="otc-static-map-img db w-100"
        src={`${url}${query}`}
        srcSet={`${url}@2x${query} 2x`}
        alt={`${title} Map`}
      />
    </div>
  );
}

StaticMap.defaultProps = {
  title: "",
  zoom: 12,
  bearing: 0,
  pitch: 0,
  height: 400,
  width: 1280,
};

StaticMap.propTypes = {
  title: PropTypes.string,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  bearing: PropTypes.number,
  pitch: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
};
