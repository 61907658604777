import map from "lodash/map";
import React from "react";
import { graphql } from "gatsby";
import Area from "../components/Area";

class AreaTemplate extends React.Component {
  render() {
    const {
      location,
      data: { site, area, services, testimonials },
    } = this.props;
    const siteTitle = site.siteMetadata.title;

    return (
      <Area
        location={location}
        siteTitle={siteTitle}
        area={area}
        testimonials={map(
          testimonials && testimonials.edges,
          (edge) => edge.node
        )}
        services={map(services && services.edges, (edge) => edge.node)}
      />
    );
  }
}

export default AreaTemplate;

export const pageQuery = graphql`
  query AreaBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    area: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        latitude
        longitude
        date(formatString: "MMMM DD, YYYY")
      }
    }
    testimonials: allMarkdownRemark(
      filter: {
        fields: { slug: { glob: "/testimonials/**" } }
        frontmatter: { areas: { eq: $slug } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            imgUrl
            date(formatString: "MMMM DD, YYYY")
          }
          html
        }
      }
    }
    services: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        fields: { slug: { glob: "/services/**" } }
        # frontmatter: { areas: { eq: $slug } }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
