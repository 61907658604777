import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Testimonials.module.scss";

const Testimonials = ({ className, testimonials, area }) => (
  <div className={cx(styles.root, className)}>
    {testimonials.map(({ id, frontmatter, html }, i) => (
      <div
        key={id}
        className={cx(
          styles.testimonial,
          i % 2 === 0 ? styles.even : styles.odd
        )}
      >
        {frontmatter.imgUrl && (
          <img className={styles.img} src={frontmatter.imgUrl} alt="" />
        )}
        <div className={styles.body}>
          <h4 className={styles.title}>{frontmatter.title}</h4>
          <div
            className={styles.html}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <p className={styles.meta}>
            by {frontmatter.title} {area && `in ${area.frontmatter.title}`} on{" "}
            {frontmatter.date}
          </p>
        </div>
      </div>
    ))}
  </div>
);

Testimonials.propTypes = {
  className: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fronmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
      }),
      html: PropTypes.string.isRequired,
    })
  ),
};

export default Testimonials;
